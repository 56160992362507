import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'http://localhost:8000';

// Base styled components
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 20px;
  padding: 20px;
  border-bottom: 1px solid #eee;
`;

const Section = styled.div`
  padding: 20px;
`;

const InputGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const StyledInput = styled.input`
  flex: 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  &:focus {
    outline: none;
    border-color: #3498db;
  }
`;

const ActionButton = styled.button`
  background: #3498db;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s;

  &:hover {
    background: #2980b9;
  }

  &:disabled {
    background: #bdc3c7;
    cursor: not-allowed;
  }
`;

const ViewDetailsButton = styled(ActionButton)`
  margin-top: 10px;
  background: #9b59b6;
  
  &:hover {
    background: #8e44ad;
  }
`;

const ViewWorksButton = styled(ActionButton)`
  margin-top: 15px;
  width: 100%;
  background: #2ecc71;
  
  &:hover {
    background: #27ae60;
  }
`;

const PaginationButton = styled(ActionButton)`
  padding: 8px 16px;
  font-size: 14px;
`;

const ResultsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const AuthorsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const AuthorCard = styled.div`
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 20px;
  transition: transform 0.2s;
  background: white;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const WorksContainer = styled.div`
  border-left: 1px solid #eee;
  padding-left: 20px;

  @media (max-width: 768px) {
    border-left: none;
    padding-left: 0;
    border-top: 1px solid #eee;
    padding-top: 20px;
  }
`;

const WorksHeader = styled.div`
  margin-bottom: 20px;
`;

const WorksTitle = styled.h2`
  color: #2c3e50;
  font-size: 20px;
  margin-bottom: 10px;
`;

const WorksCount = styled.p`
  color: #7f8c8d;
  font-size: 14px;
`;

const WorksList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const WorkCard = styled.div`
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 4px;
  background: white;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const WorkTitle = styled.h3`
  color: #2c3e50;
  font-size: 16px;
  margin-bottom: 8px;
`;

const WorkDetail = styled.p`
  color: #7f8c8d;
  font-size: 14px;
  margin: 4px 0;
`;

const WorkDescription = styled.p`
  color: #34495e;
  font-size: 14px;
  margin-top: 10px;
  line-height: 1.4;
`;

const AuthorName = styled.h2`
  color: #2c3e50;
  margin-bottom: 10px;
`;

const AuthorDetail = styled.p`
  color: #7f8c8d;
  margin: 5px 0;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
`;

const Tag = styled.span`
  background: #f0f2f5;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  color: #2c3e50;
`;

const ErrorText = styled.p`
  color: #e74c3c;
  margin-top: 10px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
`;

const PageInfo = styled.span`
  color: #7f8c8d;
  font-size: 14px;
`;

const BookDetailsContainer = styled.div`
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
`;

const BookCover = styled.img`
  max-width: 200px;
  height: auto;
  margin: 10px 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DetailTitle = styled.h4`
  color: #2c3e50;
  margin: 10px 0 5px 0;
  font-size: 14px;
  font-weight: bold;
`;

const DetailText = styled.p`
  color: #7f8c8d;
  font-size: 14px;
  margin: 5px 0;
  line-height: 1.4;
`;

const SearchAuthors = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [authors, setAuthors] = useState([]);
    const [selectedAuthor, setSelectedAuthor] = useState(null);
    const [authorWorks, setAuthorWorks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [offset, setOffset] = useState(0);
    const [totalWorks, setTotalWorks] = useState(0);
    const [selectedWork, setSelectedWork] = useState(null);
    const [bookDetails, setBookDetails] = useState(null);
    const WORKS_PER_PAGE = 20;
  
    const handleSearch = async () => {
      if (!searchQuery.trim()) {
        setError('Please enter an author name');
        return;
      }
  
      setLoading(true);
      setError(null);
      setSelectedAuthor(null);
      setAuthorWorks([]);
      setOffset(0);
      setSelectedWork(null);
      setBookDetails(null);
  
      try {
        const response = await axios.get(`${BACKEND_API_URL}/search-authors`, {
          params: { query: searchQuery }
        });
        
        if (response.data.total > 0) {
          setAuthors(response.data.authors);
        } else {
          setError('No authors found');
          setAuthors([]);
        }
      } catch (err) {
        console.error('Error searching authors:', err);
        setError('Failed to fetch authors. Please try again.');
      } finally {
        setLoading(false);
      }
    };
  
    const fetchAuthorWorks = async (authorKey, newOffset = 0) => {
      setLoading(true);
      setError(null);
      setSelectedWork(null);
      setBookDetails(null);
      
      try {
        const cleanKey = authorKey.split('/').pop();
        const response = await axios.get(`${BACKEND_API_URL}/author-works/${cleanKey}`, {
          params: {
            offset: newOffset,
            limit: WORKS_PER_PAGE
          }
        });
        
        setAuthorWorks(response.data.works);
        setTotalWorks(response.data.total_works);
        setSelectedAuthor(response.data.author);
        setOffset(newOffset);
      } catch (err) {
        console.error('Error fetching author works:', err);
        setError('Failed to fetch author works. Please try again.');
      } finally {
        setLoading(false);
      }
    };
  
    const fetchBookDetails = async (work) => {
      setLoading(true);
      setError(null);
      setSelectedWork(work);
      
      try {
        const response = await axios.get(`${BACKEND_API_URL}/book-details/${encodeURIComponent(work.title)}`, {
          params: {
            author: selectedAuthor.name
          }
        });
        
        if (response.data.books && response.data.books.length > 0) {
          setBookDetails(response.data.books[0]);
        } else {
          setError('No additional details found for this book');
          setBookDetails(null);
        }
      } catch (err) {
        console.error('Error fetching book details:', err);
        setError('Failed to fetch book details. Please try again.');
        setBookDetails(null);
      } finally {
        setLoading(false);
      }
    };
  
    const handleNextPage = () => {
      if (offset + WORKS_PER_PAGE < totalWorks) {
        fetchAuthorWorks(selectedAuthor.key, offset + WORKS_PER_PAGE);
      }
    };
  
    const handlePrevPage = () => {
      if (offset - WORKS_PER_PAGE >= 0) {
        fetchAuthorWorks(selectedAuthor.key, offset - WORKS_PER_PAGE);
      }
    };
  
    return (
      <Container>
        <ContentWrapper>
          <Title>Author Search</Title>
          
          <Section>
            <InputGroup>
              <StyledInput
                type="text"
                placeholder="Enter author name..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
              />
              <ActionButton onClick={handleSearch} disabled={loading}>
                {loading ? 'Searching...' : 'Search'}
              </ActionButton>
            </InputGroup>
            
            {error && <ErrorText>{error}</ErrorText>}
  
            <ResultsContainer>
              <AuthorsList>
                {authors.map((author, index) => (
                  <AuthorCard key={index}>
                    <AuthorName>{author.name}</AuthorName>
                    <AuthorDetail>Birth Date: {author.birth_date || 'Not Available'}</AuthorDetail>
                    <AuthorDetail>Top Work: {author.top_work || 'Not Available'}</AuthorDetail>
                    <AuthorDetail>Work Count: {author.work_count || 0}</AuthorDetail>
                    
                    {author.alternate_names && author.alternate_names.length > 0 && (
                      <>
                        <AuthorDetail>Alternative Names:</AuthorDetail>
                        <TagContainer>
                          {author.alternate_names.map((name, idx) => (
                            <Tag key={idx}>{name}</Tag>
                          ))}
                        </TagContainer>
                      </>
                    )}
                    
                    <ViewWorksButton 
                      onClick={() => fetchAuthorWorks(author.key)}
                      disabled={loading}
                    >
                      {loading ? 'Loading...' : 'View Works'}
                    </ViewWorksButton>
                  </AuthorCard>
                ))}
              </AuthorsList>
  
              {selectedAuthor && authorWorks.length > 0 && (
                <WorksContainer>
                  <WorksHeader>
                    <WorksTitle>Works by {selectedAuthor.name}</WorksTitle>
                    <WorksCount>
                      Showing {offset + 1}-{Math.min(offset + WORKS_PER_PAGE, totalWorks)} of {totalWorks} works
                    </WorksCount>
                  </WorksHeader>
  
                  <WorksList>
                    {authorWorks.map((work, index) => (
                      <WorkCard key={index}>
                        <WorkTitle>{work.title}</WorkTitle>
                        {work.first_publish_date && (
                          <WorkDetail>Published: {work.first_publish_date}</WorkDetail>
                        )}
                        {work.subjects && work.subjects.length > 0 && (
                          <>
                            <WorkDetail>Subjects:</WorkDetail>
                            <TagContainer>
                              {work.subjects.slice(0, 5).map((subject, idx) => (
                                <Tag key={idx}>{subject}</Tag>
                              ))}
                            </TagContainer>
                          </>
                        )}
                        {work.description && (
                          <WorkDescription>
                            {typeof work.description === 'object' 
                              ? work.description.value 
                              : work.description}
                          </WorkDescription>
                        )}
                        <ViewDetailsButton 
                          onClick={() => fetchBookDetails(work)}
                          disabled={loading}
                        >
                          {loading && selectedWork?.title === work.title 
                            ? 'Loading...' 
                            : 'View Details'
                          }
                        </ViewDetailsButton>
                        
                        {selectedWork?.title === work.title && bookDetails && (
                          <BookDetailsContainer>
                            {bookDetails.cover_image && (
                              <BookCover src={bookDetails.cover_image} alt={work.title} />
                            )}
                            {bookDetails.summary && (
                              <>
                                <DetailTitle>Summary:</DetailTitle>
                                <DetailText>{bookDetails.summary}</DetailText>
                              </>
                            )}
                            {bookDetails.publisher && (
                              <DetailText>Publisher: {bookDetails.publisher}</DetailText>
                            )}
                            {bookDetails.year && (
                              <DetailText>Year: {bookDetails.year}</DetailText>
                            )}
                            {bookDetails.pages && (
                              <DetailText>Pages: {bookDetails.pages}</DetailText>
                            )}
                            {bookDetails.language && (
                              <DetailText>Language: {bookDetails.language}</DetailText>
                            )}
                          </BookDetailsContainer>
                        )}
                      </WorkCard>
                    ))}
                  </WorksList>
  
                  <PaginationContainer>
                    <PaginationButton 
                      onClick={handlePrevPage} 
                      disabled={offset === 0 || loading}
                    >
                      Previous
                    </PaginationButton>
                    <PageInfo>
                      Page {Math.floor(offset / WORKS_PER_PAGE) + 1} of{' '}
                      {Math.ceil(totalWorks / WORKS_PER_PAGE)}
                    </PageInfo>
                    <PaginationButton 
                      onClick={handleNextPage}
                      disabled={offset + WORKS_PER_PAGE >= totalWorks || loading}
                    >
                      Next
                    </PaginationButton>
                  </PaginationContainer>
                </WorksContainer>
              )}
            </ResultsContainer>
          </Section>
        </ContentWrapper>
      </Container>
    );
  };
  
  export default SearchAuthors;