import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background: #FFFFFF;
  color: #000000;
`;

const Hero = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;
  margin: 80px 0;
`;

const HeroContent = styled.div`
  h1 {
    font-size: 64px;
    line-height: 1.1;
    font-weight: 700;
    color: #000000;
    
    .highlight {
      background: rgba(0, 0, 0, 0.05);
      padding: 0 8px;
      margin: 0 -8px;
      border-radius: 4px;
    }
  }

  p {
    font-size: 20px;
    line-height: 1.6;
    color: #333333;
    margin: 24px 0 32px;
    max-width: 90%;
  }
`;

const HeroImage = styled.div`
  position: relative;
  
  img {
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }
  }
`;

const Button = styled.button`
  background: #000000;
  color: #FFFFFF;
  border: none;
  padding: 16px 32px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background: #333333;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const KeyInsightsSection = styled.section`
  margin: 100px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;
`;

const InsightsContent = styled.div`
  text-align: left;
`;

const SectionTitle = styled.h2`
  font-size: 48px;
  color: #000000;
  margin-bottom: 40px;
  
  .underline {
    border-bottom: 3px solid #000000;
    padding-bottom: 4px;
  }
`;

const InsightsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const InsightItem = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  padding: 24px;
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  }

  .content {
    h3 {
      font-size: 20px;
      color: #000000;
      margin-bottom: 8px;
    }

    p {
      color: #333333;
      line-height: 1.6;
      font-size: 16px;
    }
  }
`;

const DemoSection = styled.div`
  background: #F8F8F8;
  border-radius: 12px;
  overflow: hidden;
  aspect-ratio: 16/9;
  position: relative;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    background: #000000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;

    svg {
      fill: #FFFFFF;
    }

    &:hover {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
`;

const Testimonial = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 60px auto;
  padding: 40px;
  background: #F8F8F8;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  .quote {
    font-size: 24px;
    color: #000000;
    line-height: 1.5;
    font-style: italic;
    margin-bottom: 16px;
  }

  .author {
    color: #333333;
    font-size: 18px;
    font-weight: 500;
  }
`;

const Stats = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin: 80px 0;
  text-align: center;
`;

const StatItem = styled.div`
  padding: 32px;
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  h2 {
    font-size: 48px;
    color: #000000;
    margin-bottom: 16px;
  }

  p {
    color: #333333;
    font-size: 18px;
    line-height: 1.5;
  }
`;

const Categories = styled.section`
  margin: 100px 0;
  text-align: center;

  h2 {
    font-size: 48px;
    color: #000000;
    margin-bottom: 16px;
  }

  h3 {
    color: #333333;
    font-size: 20px;
    margin-bottom: 40px;
  }
`;

const CategoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-top: 32px;
`;

const CategoryButton = styled.button`
  background: #F8F8F8;
  border: none;
  padding: 16px 24px;
  border-radius: 8px;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  &:hover {
    background: #EEEEEE;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const StatsHighlight = styled.section`
  background: #F8F8F8;
  padding: 80px 0;
  margin: 100px 0;
  text-align: center;
  border-radius: 12px;

  h2 {
    font-size: 36px;
    color: #000000;
    margin-bottom: 60px;
  }
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;

  .stat {
    h3 {
      font-size: 42px;
      color: #000000;
      margin-bottom: 16px;
    }
    p {
      font-size: 18px;
      color: #333333;
      line-height: 1.5;
    }
  }

  .footnote {
    grid-column: 1 / -1;
    color: #666666;
    font-size: 14px;
    margin-top: 20px;
  }
`;

const UseCases = styled.section`
  margin: 100px 0;
  text-align: center;

  h2 {
    font-size: 48px;
    color: #000000;
    margin-bottom: 60px;
  }
`;

const UseCaseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const UseCase = styled.div`
  padding: 32px;
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  }

  h3 {
    font-size: 20px;
    color: #000000;
    margin-bottom: 12px;
  }

  p {
    color: #333333;
    line-height: 1.6;
  }
`;

const FAQ = styled.section`
  margin: 100px 0;

  h2 {
    font-size: 48px;
    color: #000000;
    text-align: center;
    margin-bottom: 60px;
  }
`;

const FAQItem = styled.div`
  margin-bottom: 32px;
  padding: 24px;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  }

  h3 {
    font-size: 24px;
    color: #000000;
    margin-bottom: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '+';
      font-size: 24px;
      color: #000000;
    }
  }

  p {
    color: #333333;
    line-height: 1.6;
  }
`;

const CTASection = styled.section`
  text-align: center;
  margin: 100px 0;
  padding: 80px;
  background: #F8F8F8;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  h2 {
    font-size: 36px;
    color: #000000;
    margin-bottom: 24px;
  }

  p {
    color: #333333;
    margin-bottom: 32px;
    font-size: 18px;
  }
`;

const PricingSection = styled.section`
  margin: 100px 0;
  text-align: center;
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 32px;
  margin-top: 40px;
  padding: 0 20px;
`;

const PlanCard = styled.div`
  background: #FFFFFF;
  border-radius: 16px;
  padding: 40px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: 1px solid #E5E7EB;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  }

  h3 {
    font-size: 24px;
    color: #111827;
    margin-bottom: 16px;
  }

  .price {
    font-size: 48px;
    color: #111827;
    margin-bottom: 24px;

    span {
      font-size: 18px;
      color: #6B7280;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 32px;
    text-align: left;

    li {
      padding: 12px 0;
      color: #374151;
      font-size: 16px;
      display: flex;
      align-items: center;

      &:before {
        content: "✓";
        color: #059669;
        margin-right: 12px;
        font-weight: bold;
      }
    }
  }

  button {
    width: 100%;
  }
`;


const Home = () => {
  const navigate = useNavigate();

  const features = [
    {
      title: "Dynamic Book Discovery",
      description: "Find books through Cover Mode, Title Mode, or Preview Mode. It's like having a personal librarian in your pocket.",
      items: [
        "Cover Mode: Swipe through beautiful book covers",
        "Title Mode: Discover based on intriguing titles",
        "Preview Mode: Peek into random pages",
        "Advanced Filters: Refine by genre, subject, or mood"
      ]
    },
    {
      title: "AI-Powered BookSense",
      description: "Let our smart AI do the heavy lifting with instant summaries and personalized recommendations.",
      items: [
        "Instant AI Summaries",
        "Audio Summaries for on-the-go",
        "GoodReads Integration",
        "Personalized Recommendations"
      ]
    },
    {
      title: "BookAction",
      description: "Turn reading into results with actionable insights and progress tracking.",
      items: [
        "Personal goal setting",
        "Action plans from book content",
        "Progress tracking",
        "Real-world impact"
      ]
    }
  ];

  const plans = [
    {
      name: "Free",
      price: "0",
      features: [
        "15 Daily Searches",
        "Cover, Title & Random Page Modes",
        "Save up to 5 books",
        "Basic Discovery Tools"
      ]
    },
    {
      name: "Pro",
      price: "10",
      features: [
        "Unlimited Searches",
        "All Discovery Modes",
        "Unlimited Custom Shelves",
        "AI & Audio Summaries",
        "BookAction Goals",
        "Unlimited Downloads",
        "GoodReads Integration",
        "Advanced Filters"
      ]
    }
  ];

  const faqs = [
    {
      question: "What is BookHinge?",
      answer: "BookHinge is an AI-powered platform that helps readers discover books they'll love in a fun, interactive way."
    },
    {
      question: "How does BookHinge personalize recommendations?",
      answer: "Our AI learns your preferences based on your activity, saved books, and input. The more you use it, the smarter it gets."
    },
    {
      question: "What's included in the free plan?",
      answer: "Free users can explore book covers, titles, and random pages with limited searches and save up to 5 books to their shelf."
    },
    {
      question: "What makes BookHinge unique?",
      answer: "Unlike traditional platforms, we combine fun discovery modes, AI personalization, and actionable insights into one seamless experience."
    },
    {
      question: "Can I cancel my subscription anytime?",
      answer: "Yes! You can cancel your Pro plan anytime—no strings attached."
    }
  ];

  return (
    <Container>
      <Hero>
        <HeroContent>
          <h1>
            Welcome to
            <br />
            <span className="highlight">BookHinge</span>
          </h1>
          <p>
            Your Ultimate Destination for Book Discovery. We believe books have the power to change lives, 
            spark creativity, and open new doors. Find your next great read with AI-powered discovery.
          </p>
          <Button onClick={() => navigate('/covers')}>
            Start Discovering Now
          </Button>
        </HeroContent>
      </Hero>

      <KeyInsightsSection>
        {features.map((feature, index) => (
          <InsightItem key={index}>
            <div className="content">
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
              <ul>
                {feature.items.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
          </InsightItem>
        ))}
      </KeyInsightsSection>

      <Stats>
        <StatItem>
          <h2>93%</h2>
          <p>of users discover books they wouldn't have found anywhere else</p>
        </StatItem>
        <StatItem>
          <h2>85%</h2>
          <p>feel more inspired to read consistently</p>
        </StatItem>
        <StatItem>
          <h2>3x</h2>
          <p>more books discovered compared to traditional methods</p>
        </StatItem>
      </Stats>

      <Testimonial>
        <p className="quote">
          "BookHinge completely changed how I find books. I've discovered titles I never would've found on my own!"
        </p>
        <p className="author">– Sarah M., BookHinge Member</p>
      </Testimonial>

      <PricingSection>
        <SectionTitle>Plans for Every Reader</SectionTitle>
        <PricingGrid>
          {plans.map((plan, index) => (
            <PlanCard key={index}>
              <h3>{plan.name}</h3>
              <p className="price">${plan.price}<span>/month</span></p>
              <ul>
                {plan.features.map((feature, i) => (
                  <li key={i}>{feature}</li>
                ))}
              </ul>
              <Button onClick={() => navigate('/signup')}>
                {plan.name === 'Free' ? 'Get Started' : 'Upgrade to Pro'}
              </Button>
            </PlanCard>
          ))}
        </PricingGrid>
      </PricingSection>

      <FAQ>
        <h2>Frequently Asked Questions</h2>
        {faqs.map((faq, index) => (
          <FAQItem key={index}>
            <h3>{faq.question}</h3>
            <p>{faq.answer}</p>
          </FAQItem>
        ))}
      </FAQ>

      <CTASection>
        <h2>Your Next Book Is Waiting</h2>
        <p>
          Discover the smarter, faster, and more fun way to connect with books. Whether you're looking for 
          inspiration, entertainment, or knowledge, BookHinge is here to make your reading journey unforgettable.
        </p>
        <Button onClick={() => navigate('/covers')}>
          Start Discovering Now
        </Button>
      </CTASection>
    </Container>
  );
};

export default Home;