import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Filters from './Filters';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

const Container = styled.div`
  padding: 40px;
  background: #FFFFFF;
  min-height: 100vh;
  color: #111827;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  padding: 24px;
  margin-bottom: 40px;
  border-bottom: 1px solid #E5E7EB;
  z-index: 100;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
`;

const HeaderContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  font-size: 42px;
  font-weight: 700;
  color: #111827;
  margin: 0;
`;

const LanguageSelect = styled.select`
  background: #FFFFFF;
  color: #111827;
  padding: 14px 28px;
  border: 2px solid #111827;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  &:focus {
    outline: none;
    border-color: #111827;
    box-shadow: 0 0 0 3px rgba(17, 24, 39, 0.1);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;

  @media (max-width: 640px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 24px;
  }
`;

const BookCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: #FFFFFF;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  height: 100%;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.12);
  }
`;

const CoverImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 150%;
  background: #F9FAFB;
  overflow: hidden;
`;

const StyledCoverImage = styled(LazyLoadImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const LoadingPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F9FAFB;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6B7280;
  font-weight: 500;
`;

const BookInfo = styled.div`
  padding: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
`;

const BookTitle = styled.h3`
  color: #111827;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 8px;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const BookAuthor = styled.p`
  color: #4B5563;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
`;

const LoadMoreButton = styled.button`
  padding: 14px 28px;
  background: #111827;
  color: #FFFFFF;
  border: 2px solid #111827;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin: 60px auto;
  display: block;
  transition: all 0.2s ease;
  width: auto;
  min-width: 200px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background: #000000;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const BookCard = ({ book, onClick }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const coverUrl = book.cover || '/default-cover.jpg';

  return (
    <BookCardWrapper onClick={onClick}>
      <CoverImageWrapper>
        {!imageLoaded && !imageError && (
          <LoadingPlaceholder>
            Loading...
          </LoadingPlaceholder>
        )}
        <StyledCoverImage
          src={coverUrl}
          alt={`Cover of ${book.title}`}
          effect="opacity"
          threshold={100}
          beforeLoad={() => setImageLoaded(false)}
          afterLoad={() => setImageLoaded(true)}
          onError={(e) => {
            setImageError(true);
            e.target.src = '/default-cover.jpg';
          }}
          style={{ opacity: imageLoaded ? 1 : 0 }}
          placeholderSrc="/default-cover.jpg"
        />
      </CoverImageWrapper>
      <BookInfo>
        <BookTitle>{book.title || 'Unknown Title'}</BookTitle>
        <BookAuthor>{book.author || 'Unknown Author'}</BookAuthor>
      </BookInfo>
    </BookCardWrapper>
  );
};

const Covers = () => {
  const navigate = useNavigate();
  const [covers, setCovers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState('English');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const ITEMS_PER_PAGE = 12;

  useEffect(() => {
    setPage(1);
    fetchCovers(1, true);
  }, [selectedCategories]);

  const fetchCovers = async (pageNum = 1, reset = false) => {
    try {
      setLoading(true);
      const categories = selectedCategories.join(',');
      const response = await axios.get(
        `${BACKEND_API_URL}/books/covers?page=${pageNum}&limit=${ITEMS_PER_PAGE}${categories ? `&categories=${categories}` : ''}`
      );
      
      const validCovers = response.data.filter(book => 
        book.title && (book.cover || book.author)
      );
      
      if (reset || pageNum === 1) {
        setCovers(validCovers);
      } else {
        setCovers(prev => [...prev, ...validCovers]);
      }
      
      setHasMore(validCovers.length === ITEMS_PER_PAGE);
    } catch (err) {
      console.error('Error fetching covers:', err);
      setError('Failed to load covers. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleBookClick = (bookId) => {
    navigate(`/preview/${bookId}`);
  };

  const loadMore = () => {
    if (!loading && hasMore) {
      setPage(prev => prev + 1);
      fetchCovers(page + 1);
    }
  };

  return (
    <Container>
      <Header>
        <HeaderContent>
          <TopBar>
            <Title>All Covers</Title>
            <LanguageSelect value={language} onChange={(e) => setLanguage(e.target.value)}>
              <option value="English">English</option>
            </LanguageSelect>
          </TopBar>
          <Filters 
            selectedCategories={selectedCategories}
            onCategoryChange={setSelectedCategories}
          />
        </HeaderContent>
      </Header>
      <Grid>
        {covers.map((book) => (
          <BookCard
            key={book.id}
            book={book}
            onClick={() => handleBookClick(book.id)}
          />
        ))}
      </Grid>
      {hasMore && (
        <LoadMoreButton onClick={loadMore} disabled={loading}>
          {loading ? 'Loading...' : 'Load More'}
        </LoadMoreButton>
      )}
    </Container>
  );
};

export default Covers;