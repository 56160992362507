import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import AudioSummary from './AudioSummary';
import GoodReads from './GoodReads';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

const Container = styled.div`
  padding: 40px;
  background: #FFFFFF;
  min-height: 100vh;
  color: #111827;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
`;

const BookHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 40px;
  align-items: start;
  margin-bottom: 60px;
  text-align: left;
`;

const CoverImage = styled.img`
  width: 300px;
  border-radius: 16px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const BookInfo = styled.div`
  h2 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 8px;
    color: #111827;
  }

  h3 {
    font-size: 24px;
    color: #4B5563;
    margin-bottom: 24px;
    font-weight: 500;
  }
`;

const PreviewContent = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 40px;
  background: #F9FAFB;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  
  p {
    font-size: 18px;
    line-height: 1.8;
    color: #1F2937;
    white-space: pre-wrap;
    margin-bottom: 20px;
  }
`;

const Button = styled.button`
  padding: 14px 28px;
  background: ${props => props.primary ? '#111827' : '#FFFFFF'};
  color: ${props => props.primary ? '#FFFFFF' : '#111827'};
  border: 2px solid #111827;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 0 10px;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background: ${props => props.primary ? '#000000' : '#F9FAFB'};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin: 40px 0;
`;

const PremiumFeatureNote = styled.div`
  background: linear-gradient(135deg, #111827 0%, #1F2937 100%);
  color: #FFFFFF;
  padding: 24px;
  border-radius: 12px;
  margin: 40px auto;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-weight: 500;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  
  span {
    font-size: 24px;
    margin-right: 8px;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    background: #FFFFFF;
    padding: 40px;
    border-radius: 16px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    
    h3 {
      font-size: 24px;
      margin-bottom: 24px;
      color: #111827;
    }

    .shelf-grid {
      display: grid;
      gap: 12px;
      margin-bottom: 24px;
    }
  }
`;

const CoverPreview = () => {
  const { bookId } = useParams();
  const navigate = useNavigate();
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showGoodReads, setShowGoodReads] = useState(false);
  const [premiumFeatures, setPremiumFeatures] = useState(null);
  const [userId, setUserId] = useState(null);
  const [showShelfModal, setShowShelfModal] = useState(false);
  const [shelves, setShelves] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      navigate('/signin');
      return;
    }

    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      setUserId(payload.user_id);
    } catch (error) {
      console.error('Error decoding token:', error);
      navigate('/signin');
    }
  }, [navigate]);

  useEffect(() => {
    if (userId) {
      fetchPreview();
    }
  }, [userId, bookId]);

  useEffect(() => {
    if (userId) {
      fetchShelves();
    }
  }, [userId]);

  const fetchPreview = async () => {
    try {
      const response = await axios.get(`${BACKEND_API_URL}/books/preview/${bookId}`, {
        params: { user_id: userId }
      });
      
      setPremiumFeatures(response.data.premium_features);
      setPreview(response.data);
    } catch (err) {
      console.error('Error fetching preview:', err);
      if (err.response?.status === 401) {
        navigate('/signin');
      } else {
        setError('Failed to load preview. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchShelves = async () => {
    try {
      const response = await axios.get(`${BACKEND_API_URL}/bookshelf/shelves`, {
        params: { user_id: userId }
      });
      setShelves(response.data);
    } catch (err) {
      console.error('Error fetching shelves:', err);
    }
  };

  const handleGoodReadsClick = () => {
    if (!premiumFeatures?.goodreads) {
      alert('GoodReads integration is a premium feature. Upgrade to Pro to access book reviews!');
      return;
    }
    setShowGoodReads(!showGoodReads);
  };

  const handleAddToShelf = async (shelfId) => {
    try {
      await axios.post(`${BACKEND_API_URL}/bookshelf/${shelfId}/add`, {
        user_id: userId,
        book_id: preview.id
      });
      setShowShelfModal(false);
      alert('Book added to shelf successfully!');
    } catch (err) {
      console.error('Error adding to shelf:', err);
      alert(err.response?.data?.detail || 'Failed to add book to shelf');
    }
  };

  if (loading) return <Container><div style={{ textAlign: 'center', padding: '100px' }}>Loading your preview...</div></Container>;
  if (error) return <Container><div style={{ textAlign: 'center', padding: '100px', color: '#EF4444' }}>{error}</div></Container>;
  if (!preview) return <Container><div style={{ textAlign: 'center', padding: '100px' }}>No preview available</div></Container>;

  return (
    <Container>
      <BookHeader>
        <CoverImage
          src={`${BACKEND_API_URL}/books/cover/${bookId}`}
          alt={`Cover of ${preview.title}`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/default-cover.jpg';
          }}
        />
        <BookInfo>
          <h2>{preview.title}</h2>
          <h3>by {preview.author}</h3>
          <ButtonGroup>
            <Button primary onClick={() => setShowShelfModal(true)}>
              Add to Shelf
            </Button>
            <Button 
              onClick={handleGoodReadsClick}
              disabled={!premiumFeatures?.goodreads}
            >
              {showGoodReads ? 'Hide Reviews' : 'Show Reviews'}
              {!premiumFeatures?.goodreads && ' (Pro)'}
            </Button>
            <Button onClick={() => navigate('/covers')}>
              Back to Covers
            </Button>
          </ButtonGroup>
        </BookInfo>
      </BookHeader>

      <PreviewContent>
        <p>{preview.pdf_preview || preview.epub_preview}</p>
      </PreviewContent>

      {!premiumFeatures?.ai_summary && (
        <PremiumFeatureNote>
          <span>⭐️</span> Upgrade to Pro to access AI-powered book summaries
        </PremiumFeatureNote>
      )}
      
      {preview.ai_summary && premiumFeatures?.ai_summary && (
        <AudioSummary 
          bookId={preview.id} 
          summary={preview.ai_summary}
        />
      )}

      {showGoodReads && premiumFeatures?.goodreads && (
        <GoodReads 
          title={preview.title}
          author={preview.author}
        />
      )}

      {showShelfModal && (
        <Modal onClick={() => setShowShelfModal(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h3>Add to Shelf</h3>
            <div className="shelf-grid">
              {shelves.map(shelf => (
                <Button 
                  key={shelf.id} 
                  onClick={() => handleAddToShelf(shelf.id)}
                  primary
                >
                  {shelf.name}
                </Button>
              ))}
            </div>
            <Button onClick={() => setShowShelfModal(false)}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}
    </Container>
  );
};

export default CoverPreview;