import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import styled from 'styled-components';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

function VerifyRegister() {
    const { token } = useParams();
    const navigate = useNavigate();
    const { setUser } = useUser();
    const [status, setStatus] = useState('Verifying your email...');

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await fetch(`${BACKEND_API_URL}/verify-email/${token}`);
                const data = await response.json();

                if (response.ok && data.message === 'Email verification successful') {
                    setStatus('Email verified successfully! Redirecting...');
                    setUser({ email: data.email });
                    localStorage.setItem('authToken', data.access_token);
                    setTimeout(() => {
                        navigate('/features');
                        window.location.reload();
                    }, 2000);
                } else {
                    setStatus('Verification failed. Please try again or contact support.');
                }
            } catch (error) {
                console.error('Verification error:', error);
                setStatus('Verification failed. Please try again or contact support.');
            }
        };

        verifyEmail();
    }, [token, navigate, setUser]);

    return (
        <Container>
            <VerificationBox>
                <Status>{status}</Status>
            </VerificationBox>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f8f8f8;
`;

const VerificationBox = styled.div`
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
`;

const Status = styled.p`
    font-size: 1.2rem;
    color: #333;
`;

export default VerifyRegister;