import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FaBook, FaSpinner } from 'react-icons/fa';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 24px;
  background: #FFFFFF;
  min-height: 100vh;
  color: #111827;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
`;

const Form = styled.form`
  background: #FFFFFF;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  border: 1px solid #E5E7EB;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 16px;
  margin: 20px 0;
  border: 2px solid #E5E7EB;
  border-radius: 8px;
  min-height: 160px;
  font-size: 16px;
  font-family: inherit;
  resize: vertical;
  transition: all 0.2s ease;
  line-height: 1.6;
  color: #111827;

  &:focus {
    outline: none;
    border-color: #111827;
    box-shadow: 0 0 0 3px rgba(17, 24, 39, 0.1);
  }

  &::placeholder {
    color: #9CA3AF;
  }
`;

const Button = styled.button`
  background: #111827;
  color: white;
  border: none;
  padding: 16px 32px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background: #000000;
  }

  &:disabled {
    background: #E5E7EB;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const RecommendationCard = styled.div`
  background: #FFFFFF;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;
  border: 1px solid #E5E7EB;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const Title = styled.h2`
  color: #111827;
  margin-bottom: 24px;
  font-size: ${props => props.main ? '42px' : '32px'};
  font-weight: 700;
  line-height: 1.2;
`;

const Section = styled.div`
  margin: 24px 0;
  
  h3 {
    color: #111827;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  
  p {
    color: #4B5563;
    line-height: 1.8;
    font-size: 16px;
  }
`;

const LoadingSpinner = styled(FaSpinner)`
  animation: spin 1s linear infinite;
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const ErrorMessage = styled.div`
  color: #EF4444;
  padding: 16px;
  border-radius: 8px;
  margin: 20px 0;
  background: #FEF2F2;
  border: 1px solid #FCA5A5;
  font-weight: 500;
`;

const BookSense = () => {
  const [prompt, setPrompt] = useState('');
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setRecommendations([]);

    try {
      const response = await axios.post(`${BACKEND_API_URL}/booksense/recommend`, {
        prompt
      });
      setRecommendations(response.data.recommendations);
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to get recommendations');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Title main>BookSense - Feel-Based Book Recommendations</Title>
        
        <TextArea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="What kind of book are you in the mood for? (e.g., 'I've been in the mood to escape reality with a science fiction book about exploring the universe and colonizing the stars. Looking for hope, beauty, wonder & awe at the vastness of space. Fun science concepts and shiny new technology. Something that feels like Mass Effect: Andromeda - not another cynical dystopia!')"
          required
        />

        <Button type="submit" disabled={loading || !prompt}>
          {loading ? (
            <>
              <LoadingSpinner />
              Finding Books...
            </>
          ) : (
            <>
              <FaBook />
              Get Recommendations
            </>
          )}
        </Button>
      </Form>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      {recommendations.map((book, index) => (
        <RecommendationCard key={index}>
          <Title>{book.title}</Title>
          <Section>
            <h3>Plot Summary</h3>
            <p>{book.plot}</p>
          </Section>
          <Section>
            <h3>Why This Matches Your Request</h3>
            <p>{book.mood_match}</p>
          </Section>
          <Section>
            <h3>What Makes It Special</h3>
            <p>{book.special_features}</p>
          </Section>
        </RecommendationCard>
      ))}
    </Container>
  );
};

export default BookSense;