import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

const Container = styled.div`
  padding: 40px 24px;
  background: #FFFFFF;
  min-height: 100vh;
  color: #111827;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
`;

const Form = styled.form`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Section = styled.section`
  background: #FFFFFF;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  border: 1px solid #E5E7EB;
`;

const Title = styled.h1`
  font-size: 42px;
  font-weight: 700;
  color: #111827;
  margin-bottom: 40px;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 24px;
`;

const InputGroup = styled.div`
  margin-bottom: 24px;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #E5E7EB;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #111827;
    box-shadow: 0 0 0 3px rgba(17, 24, 39, 0.1);
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #E5E7EB;
  border-radius: 8px;
  font-size: 16px;
  background: white;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #111827;
    box-shadow: 0 0 0 3px rgba(17, 24, 39, 0.1);
  }
`;

const Button = styled.button`
  background: #111827;
  color: white;
  padding: 16px 32px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background: #E5E7EB;
    cursor: not-allowed;
  }
`;

const Checkbox = styled.input`
  margin-right: 8px;
`;

const Profile = () => {
  const [profile, setProfile] = useState({
    preferred_discovery_mode: 'automatic',
    favorite_genres: [],
    excluded_genres: [],
    reading_frequency: 'daily',
    preferred_book_length: 'medium',
    language_preferences: ['english'],
    auto_download_enabled: false,
    notification_preferences: {
      new_books: true,
      recommendations: true,
      system_updates: true
    }
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${BACKEND_API_URL}/profile`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setProfile(response.data);
    } catch (err) {
      setError('Failed to load profile');
      console.error(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    try {
      const token = localStorage.getItem('authToken');
      await axios.post(`${BACKEND_API_URL}/profile/update`, profile, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setSuccess('Profile updated successfully!');
    } catch (err) {
      setError('Failed to update profile');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Title>Profile Settings</Title>

        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && <SuccessMessage>{success}</SuccessMessage>}

        <Section>
          <SectionTitle>Discovery Preferences</SectionTitle>
          <InputGroup>
            <Label>Discovery Mode</Label>
            <Select 
              value={profile.preferred_discovery_mode}
              onChange={e => setProfile({...profile, preferred_discovery_mode: e.target.value})}
            >
              <option value="automatic">Automatic</option>
              <option value="manual">Manual</option>
              <option value="hybrid">Hybrid</option>
            </Select>
          </InputGroup>

          <InputGroup>
            <Label>Favorite Genres</Label>
            <Select 
              multiple
              value={profile.favorite_genres}
              onChange={e => setProfile({
                ...profile, 
                favorite_genres: Array.from(e.target.selectedOptions, option => option.value)
              })}
            >
              <option value="fiction">Fiction</option>
              <option value="non-fiction">Non-Fiction</option>
              <option value="science">Science</option>
              <option value="technology">Technology</option>
              <option value="business">Business</option>
            </Select>
          </InputGroup>
        </Section>

        <Section>
          <SectionTitle>Reading Preferences</SectionTitle>
          <InputGroup>
            <Label>Reading Frequency</Label>
            <Select
              value={profile.reading_frequency}
              onChange={e => setProfile({...profile, reading_frequency: e.target.value})}
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </Select>
          </InputGroup>

          <InputGroup>
            <Label>Preferred Book Length</Label>
            <Select
              value={profile.preferred_book_length}
              onChange={e => setProfile({...profile, preferred_book_length: e.target.value})}
            >
              <option value="short">Short</option>
              <option value="medium">Medium</option>
              <option value="long">Long</option>
            </Select>
          </InputGroup>
        </Section>

        <Section>
          <SectionTitle>Notification Preferences</SectionTitle>
          <InputGroup>
            <Label>
              <Checkbox
                type="checkbox"
                checked={profile.notification_preferences.new_books}
                onChange={e => setProfile({
                  ...profile,
                  notification_preferences: {
                    ...profile.notification_preferences,
                    new_books: e.target.checked
                  }
                })}
              />
              New Books Notifications
            </Label>
          </InputGroup>

          <InputGroup>
            <Label>
              <Checkbox
                type="checkbox"
                checked={profile.auto_download_enabled}
                onChange={e => setProfile({...profile, auto_download_enabled: e.target.checked})}
              />
              Enable Auto-Download
            </Label>
          </InputGroup>
        </Section>

        <Button type="submit" disabled={loading}>
          {loading ? 'Saving...' : 'Save Changes'}
        </Button>
      </Form>
    </Container>
  );
};

const ErrorMessage = styled.div`
  color: #DC2626;
  background: #FEE2E2;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
`;

const SuccessMessage = styled.div`
  color: #059669;
  background: #D1FAE5;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
`;

export default Profile;