import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

const Container = styled.div`
  padding: 40px 24px;
  max-width: 1000px;
  margin: 0 auto;
  background: #FFFFFF;
  min-height: 100vh;
  color: #111827;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
`;

const SearchForm = styled.form`
  display: flex;
  gap: 16px;
  margin-bottom: 40px;
  background: #FFFFFF;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  border: 1px solid #E5E7EB;
`;

const Input = styled.input`
  padding: 16px;
  border: 2px solid #E5E7EB;
  border-radius: 8px;
  flex: 1;
  font-size: 16px;
  transition: all 0.2s ease;
  color: #111827;

  &:focus {
    outline: none;
    border-color: #111827;
    box-shadow: 0 0 0 3px rgba(17, 24, 39, 0.1);
  }

  &::placeholder {
    color: #9CA3AF;
  }
`;

const Button = styled.button`
  padding: 16px 32px;
  background: #111827;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease;
  white-space: nowrap;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background: #000000;
  }

  &:disabled {
    background: #E5E7EB;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const ResultCard = styled.div`
  border: 1px solid #E5E7EB;
  padding: 32px;
  margin-bottom: 24px;
  border-radius: 16px;
  background: #FFFFFF;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    margin: 0 0 16px;
  }

  p {
    color: #4B5563;
    font-size: 16px;
    line-height: 1.6;
    margin: 8px 0;
  }
`;

const DownloadLink = styled.a`
  color: #111827;
  text-decoration: none;
  display: block;
  margin-top: 16px;
  padding: 12px 24px;
  border: 2px solid #111827;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
  text-align: center;

  &:hover {
    background: #F9FAFB;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const ErrorMessage = styled.div`
  color: #EF4444;
  padding: 16px;
  border-radius: 8px;
  margin: 20px 0;
  background: #FEF2F2;
  border: 1px solid #FCA5A5;
  font-weight: 500;
`;

const Title = styled.h1`
  font-size: 42px;
  font-weight: 700;
  color: #111827;
  margin-bottom: 40px;
  text-align: center;
`;

const DownloadUrl = () => {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [downloadUrls, setDownloadUrls] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${BACKEND_API_URL}/books/search/${encodeURIComponent(query)}`);
      setSearchResults(response.data);
    } catch (err) {
      setError('Error searching books. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getDownloadUrl = async (bookId) => {
    try {
      const response = await axios.get(`${BACKEND_API_URL}/books/download/${bookId}`);
      setDownloadUrls(prev => ({
        ...prev,
        [bookId]: response.data
      }));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <Title>Book Downloads</Title>
      
      <SearchForm onSubmit={handleSearch}>
        <Input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search for a book..."
        />
        <Button type="submit" disabled={loading}>
          {loading ? 'Searching...' : 'Search'}
        </Button>
      </SearchForm>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      {searchResults.map((book) => (
        <ResultCard key={book.bookId}>
          <h3>{book.title}</h3>
          <p>Author: {book.authors}</p>
          <p>Year: {book.year}</p>
          <p>Pages: {book.pages}</p>
          {!downloadUrls[book.bookId] ? (
            <Button onClick={() => getDownloadUrl(book.bookId)}>
              Get Download Link
            </Button>
          ) : (
            <>
              <DownloadLink href={downloadUrls[book.bookId].mirror1} target="_blank">
                Download Mirror 1
              </DownloadLink>
              {downloadUrls[book.bookId].mirror2 && (
                <DownloadLink href={downloadUrls[book.bookId].mirror2} target="_blank">
                  Download Mirror 2
                </DownloadLink>
              )}
              {downloadUrls[book.bookId].mirror3 && (
                <DownloadLink href={downloadUrls[book.bookId].mirror3} target="_blank">
                  Download Mirror 3
                </DownloadLink>
              )}
            </>
          )}
        </ResultCard>
      ))}
    </Container>
  );
};

export default DownloadUrl;