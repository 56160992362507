import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

function Register() {
    const [email, setEmail] = useState('');
    const [registrationStatus, setRegistrationStatus] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);
        
        try {
            const response = await fetch(`${BACKEND_API_URL}/register`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
    
            if (!response.ok) {
                throw new Error('Registration failed');
            }
    
            window.alert(
                `Registration successful! Please check your email at ${email} to verify your account.\n\n` +
                `Note: You'll start with a free account which includes:\n` +
                `• Unlimited cover and title browsing\n` +
                `• 15 book previews per day\n` +
                `• Save up to 5 books\n` +
                `• Basic BookSense recommendations\n\n` +
                `Upgrade to Pro for unlimited access!`
            );
        } catch (error) {
            console.error('Error:', error);
            setRegistrationStatus('Registration failed. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Container>
            <RegisterBox>
                <Title>Join BookHinge</Title>
                <Subtitle>Your Gateway to Endless Book Discovery</Subtitle>
                
                <Form onSubmit={handleSubmit}>
                    <Input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <Button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Creating Account...' : 'Create Free Account'}
                    </Button>
                </Form>

                <FeaturesContainer>
                    <FeaturesColumn>
                        <Features>
                            <FeatureTitle>Free Account Includes:</FeatureTitle>
                            <FeatureList>
                                <FeatureItem>✓ Unlimited cover and title browsing</FeatureItem>
                                <FeatureItem>✓ 15 book previews per day</FeatureItem>
                                <FeatureItem>✓ Save up to 5 books</FeatureItem>
                                <FeatureItem>✓ Basic BookSense recommendations</FeatureItem>
                            </FeatureList>
                        </Features>
                    </FeaturesColumn>

                    <Divider />

                    <FeaturesColumn>
                        <Features $isPro>
                            <FeatureTitle>Pro Account Includes:</FeatureTitle>
                            <FeatureList>
                                <FeatureItem>✓ Unlimited book previews</FeatureItem>
                                <FeatureItem>✓ Unlimited book saves</FeatureItem>
                                <FeatureItem>✓ Create custom bookshelves</FeatureItem>
                                <FeatureItem>✓ Advanced BookSense AI recommendations</FeatureItem>
                                <FeatureItem>✓ AI-powered book summaries</FeatureItem>
                                <FeatureItem>✓ Audio book summaries</FeatureItem>
                                <FeatureItem>✓ BookAction feature access</FeatureItem>
                                <FeatureItem>✓ GoodReads integration</FeatureItem>
                                <FeatureItem>✓ Download books in PDF/EPUB</FeatureItem>
                            </FeatureList>
                            <ProButton href="https://analyticpinnacle.thrivecart.com/bookhinge/" target="_blank">
                                Upgrade to Pro
                            </ProButton>
                        </Features>
                    </FeaturesColumn>
                </FeaturesContainer>

                {registrationStatus && <StatusMessage>{registrationStatus}</StatusMessage>}
                
                <LoginPrompt>
                    Already have an account?
                    <StyledLink to="/signin">Sign in here</StyledLink>
                </LoginPrompt>
            </RegisterBox>
        </Container>
    );
}

// Styled components...
const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f8f8f8;
    padding: 20px;
`;

const RegisterBox = styled.div`
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
`;

const Title = styled.h1`
    font-size: 24px;
    color: #000;
    text-align: center;
    margin-bottom: 10px;
`;

const Subtitle = styled.p`
    font-size: 16px;
    color: #666;
    text-align: center;
    margin-bottom: 30px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const Input = styled.input`
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
`;

const Button = styled.button`
    padding: 12px;
    background: #000;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
        background: #333;
    }

    &:disabled {
        background: #666;
        cursor: not-allowed;
    }
`;

const FeaturesContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 30px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const FeaturesColumn = styled.div`
    flex: 1;
`;

const Divider = styled.div`
    width: 1px;
    background: #ddd;
    margin: 0 20px;

    @media (max-width: 768px) {
        width: 100%;
        height: 1px;
        margin: 20px 0;
    }
`;

const Features = styled.div`
    padding: 15px;
    background: ${props => props.$isPro ? '#f8f8f8' : '#f8f8f8'};
    border-radius: 4px;
    border: ${props => props.$isPro ? '1px solid #000' : 'none'};
`;

const FeatureTitle = styled.h3`
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
`;

const FeatureList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const FeatureItem = styled.li`
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
`;

const StatusMessage = styled.div`
    color: red;
    text-align: center;
    margin-top: 15px;
`;

const LoginPrompt = styled.div`
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #666;
`;

const StyledLink = styled(Link)`
    color: #000;
    text-decoration: none;
    margin-left: 5px;
    font-weight: bold;

    &:hover {
        text-decoration: underline;
    }
`;

const ProButton = styled.a`
    display: block;
    text-align: center;
    padding: 12px;
    background: #000;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 15px;
    font-weight: 500;
    transition: background 0.2s;

    &:hover {
        background: #333;
    }
`;

export default Register;
