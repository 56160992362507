import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from "./components/Header";
import Layout from './components/Layout';
import SignIn from "./components/SignIn";
import Register from "./components/Register";
import VerifyLogin from "./components/VerifyLogin";
import Home from './components/Home';
import VerifyRegister from "./components/VerifyRegister";
import { UserProvider } from "./components/UserContext";
import SearchJobs from './components/SearchJobs';
import LinkedinJobID from './components/LinkedinJobID';
import Profile from './components/Profile';
import Billing from './components/Billing'; 
import Features from './components/Features';
import JobSearch from './components/JobSearch'; 
import SearchAuthors from './components/SearchAuthors';
import Discovery from './components/Discovery';
import DownloadUrl from './components/DownloadUrl';
import BookSense from './components/BookSense';
import BookAction from './components/BookAction';
import Filters from './components/Filters';
import DiscoveryTitle from './components/DiscoveryTitle';
import DiscoveryCover from './components/DiscoveryCover';
import DiscoveryPreview from './components/DiscoveryPreview';
import Covers from './components/Covers';
import CoverPreview from './components/CoverPreview';
import AudioSummary from './components/AudioSummary';
import GoodReads from './components/GoodReads';
import BookShelf from './components/BookShelf';






function App() {
  const [filters, setFilters] = useState({});

  const handleSearch = (filterData) => {
    setFilters(filterData);
  };

  return (
    <UserProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/verify-login/:token" element={<VerifyLogin />} />
          <Route path="/verify-email/:token" element={<VerifyRegister />} />
          <Route path="/register" element={<Register />} />
          <Route path="/search-authors" element={<SearchAuthors />} />
          <Route path="discovery/*" element={<Discovery />} />
          <Route path="/discovery-title" element={<DiscoveryTitle />} />
          <Route path="/discovery-cover" element={<DiscoveryCover />} />
          <Route path="/discovery-preview" element={<DiscoveryPreview />} />
          <Route path="/preview/:bookId" element={<CoverPreview />} />
          <Route path="/bookshelf" element={<BookShelf />} />
          <Route path="/covers" element={<Covers />} />
          <Route path="/download-url" element={<DownloadUrl />} />
          <Route path="/download-url/:bookId" element={<DownloadUrl />} />
          <Route path="/book-sense" element={<BookSense />} />
          <Route path="/book-action" element={<BookAction />} />
          <Route path="/audio-summary/:bookId" element={<AudioSummary />} />
          <Route path="/goodreads/:title/:author" element={<GoodReads />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/search-jobs" element={<SearchJobs />} />
          <Route path="/linkedin-job/:jobId" element={<LinkedinJobID />} />
          <Route path="/linkedin-job-id" element={<LinkedinJobID />} />
          <Route path="/billing" element={<Billing />} /> 
          <Route path="/features" element={<Features />} />
          <Route path="/job-search-blueprint" element={<JobSearch />} />
        </Routes>
      </Layout>
    </UserProvider>
  );
}

export default App;