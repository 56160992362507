import React, { useState, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

const Container = styled.div`
  padding: 40px;
  max-width: 800px;
  margin: 40px auto;
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  color: #111827;
`;

const AudioPlayer = styled.div`
  position: relative;
  background: #F9FAFB;
  border-radius: 12px;
  padding: 32px;
  margin: 20px 0;

  audio {
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    
    &::-webkit-media-controls-panel {
      background: #FFFFFF;
    }
    
    &::-webkit-media-controls-play-button,
    &::-webkit-media-controls-mute-button {
      background-color: #111827;
      border-radius: 50%;
    }
    
    &::-webkit-media-controls-current-time-display,
    &::-webkit-media-controls-time-remaining-display {
      color: #111827;
    }
    
    &::-webkit-media-controls-timeline {
      background-color: #E5E7EB;
    }
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #111827;
  color: #FFFFFF;
  border: none;
  padding: 14px 28px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background: #E5E7EB;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${props => props.error ? '#EF4444' : '#6B7280'};
  font-size: 14px;
  margin-top: 12px;
  padding: 12px;
  border-radius: 8px;
  background: ${props => props.error ? '#FEF2F2' : '#F3F4F6'};

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Header = styled.div`
  margin-bottom: 24px;
  
  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 12px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  p {
    color: #6B7280;
    font-size: 16px;
    line-height: 1.5;
  }
`;

const AudioSummary = ({ bookId, summary }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const audioRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState(null);

  const generateAndPlayAudio = async () => {
    try {
      setIsGenerating(true);
      setError(null);
  
      const cleanedSummary = summary
        .replace(/[\n\r]/g, ' ')
        .replace(/"/g, '\\"')
        .trim();
  
      const response = await axios.post(
        `${BACKEND_API_URL}/generate-audio-stream/${bookId}`,
        { text: cleanedSummary },
        { 
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'audio/mpeg'
          }
        }
      );
  
      const audioBlob = new Blob([response.data], { 
        type: 'audio/mpeg'
      });
  
      if (audioBlob.size < 1000) {
        throw new Error('Audio data too small, likely invalid');
      }
  
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
  
      const url = URL.createObjectURL(audioBlob);
  
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current.src = url;
        setAudioUrl(url);
  
        audioRef.current.onloadeddata = () => {
          audioRef.current.play().catch(e => {
            console.error('Play failed:', e);
            setError('Failed to play audio. Please try again.');
          });
        };
  
        audioRef.current.onerror = (e) => {
          console.error('Audio error:', e);
          setError('Audio playback error. Please try again.');
        };
  
        await audioRef.current.load();
      }
  
    } catch (err) {
      console.error('Error generating audio:', err);
      setError('Failed to generate audio. Please try again.');
    } finally {
      setIsGenerating(false);
    }
  };

  React.useEffect(() => {
    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [audioUrl]);

  return (
    <Container>
      <Header>
        <h3>
          <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M12 6v12M8 9v6M16 9v6M20 12c0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8 8 3.6 8 8z"/>
          </svg>
          AI Audio Summary
        </h3>
        <p>Listen to an AI-generated summary of the key insights from this book.</p>
      </Header>

      <AudioPlayer>
        <audio ref={audioRef} controls />
        <Controls>
          <Button 
            onClick={generateAndPlayAudio}
            disabled={isGenerating || !summary}
          >
            {isGenerating ? (
              <>
                <svg className="animate-spin" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path d="M12 22C6.5 22 2 17.5 2 12S6.5 2 12 2s10 4.5 10 10" strokeLinecap="round"/>
                </svg>
                Generating...
              </>
            ) : (
              <>
                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path d="M5 12l14-7v14z"/>
                </svg>
                Generate & Play
              </>
            )}
          </Button>
        </Controls>
        {error && (
          <Status error>
            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>
            {error}
          </Status>
        )}
        {isGenerating && (
          <Status>
            <svg className="animate-spin" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path d="M12 22C6.5 22 2 17.5 2 12S6.5 2 12 2s10 4.5 10 10" strokeLinecap="round"/>
            </svg>
            Generating audio summary...
          </Status>
        )}
      </AudioPlayer>
    </Container>
  );
};

export default AudioSummary;