import React from 'react';
import { Link, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import DiscoveryTitle from './DiscoveryTitle';
import DiscoveryCover from './DiscoveryCover';
import DiscoveryPreview from './DiscoveryPreview';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 40px;
  background: #FFFFFF;
  min-height: 100vh;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 80px;
    height: 4px;
    background: #111827;
    border-radius: 2px;
  }
`;

const Title = styled.h1`
  font-size: 42px;
  font-weight: 800;
  color: #111827;
  letter-spacing: -0.02em;
  margin: 0;
`;

const Navigation = styled.nav`
  display: flex;
  gap: 16px;
  background: #F9FAFB;
  padding: 8px;
  border-radius: 12px;
  margin-bottom: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const NavLink = styled(Link)`
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  ${({ active }) => active ? `
    background: #111827;
    color: #FFFFFF;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  ` : `
    background: transparent;
    color: #4B5563;

    &:hover {
      background: #E5E7EB;
      color: #111827;
    }
  `}

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(255, 255, 255, 0.2), transparent);
    transform: translateY(-100%);
    transition: transform 0.3s ease;
  }

  &:hover::after {
    transform: translateY(0);
  }
`;

const ContentContainer = styled.div`
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  min-height: 500px;
  padding: 40px;
  position: relative;
`;

const Discovery = () => {
  const location = useLocation();
  const currentMode = location.pathname.split('/').pop();

  const modes = [
    { path: 'cover', label: 'Cover Mode', icon: '📚' },
    { path: 'title', label: 'Title Mode', icon: '📝' },
    { path: 'preview', label: 'Preview Mode', icon: '👀' }
  ];

  return (
    <Container>
      <Header>
        <Title>Discovery Hub</Title>
      </Header>

      <Navigation>
        {modes.map(({ path, label, icon }) => (
          <NavLink
            key={path}
            to={`/discovery/${path}`}
            active={currentMode === path ? 1 : 0}
          >
            <span style={{ marginRight: '8px' }}>{icon}</span>
            {label}
          </NavLink>
        ))}
      </Navigation>

      <ContentContainer>
        <Routes>
          <Route path="/" element={<Navigate to="/discovery/cover" replace />} />
          <Route path="/title" element={<DiscoveryTitle />} />
          <Route path="/cover" element={<DiscoveryCover />} />
          <Route path="/preview" element={<DiscoveryPreview />} />
          <Route path="*" element={<Navigate to="/discovery/cover" replace />} />
        </Routes>
      </ContentContainer>
    </Container>
  );
};

export default Discovery;