import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

const Container = styled.div`
  padding: 40px;
  background: #FFFFFF;
  min-height: 100vh;
  color: #111827;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
`;

const CoverContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  padding: 40px;
  background: #FFFFFF;
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CoverImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 150%;
  background: #F3F4F6;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
`;

const StyledCoverImage = styled(LazyLoadImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: center;

  &:hover {
    transform: scale(1.02);
  }
`;

const LoadingPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #F3F4F6, #E5E7EB);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6B7280;
  font-weight: 500;
  font-size: 16px;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
    animation: loading 1.5s infinite;
  }

  @keyframes loading {
    100% {
      left: 150%;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`;

const Button = styled.button`
  padding: 16px 32px;
  background-color: #111827;
  color: #FFFFFF;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(255, 255, 255, 0.2), transparent);
    transform: translateY(-100%);
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

    &::after {
      transform: translateY(0);
    }
  }

  &:disabled {
    background-color: #E5E7EB;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const ShelfButton = styled(Button)`
  background-color: #4F46E5;
  
  &:hover {
    background-color: #4338CA;
  }
`;

const ErrorMessage = styled.div`
  padding: 24px;
  background: #FEF2F2;
  color: #991B1B;
  border-radius: 12px;
  font-weight: 500;
  margin: 40px auto;
  max-width: 500px;
  text-align: center;
`;

const LoadingMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  font-size: 18px;
  color: #6B7280;
  font-weight: 500;
`;

const DiscoveryCover = () => {
  const [bookIds, setBookIds] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [userShelves, setUserShelves] = useState([]);

  useEffect(() => {
    console.log('Current Index:', currentIndex);
    console.log('Current Book ID:', bookIds[currentIndex]);
  }, [currentIndex, bookIds]);

  useEffect(() => {
    fetchBookIds();
    fetchUserShelves();
  }, []);

  const fetchUserShelves = async () => {
    try {
      const response = await axios.get(`${BACKEND_API_URL}/bookshelf/shelves?user_id=1`);
      setUserShelves(response.data);
    } catch (err) {
      console.error('Error fetching shelves:', err);
    }
  };

  const addToShelf = async (shelfId) => {
    try {
      const currentBookId = bookIds[currentIndex];
      await axios.post(`${BACKEND_API_URL}/bookshelf/${shelfId}/add`, {
        user_id: 1,
        book_id: currentBookId
      });
      alert('Book added to shelf successfully!');
    } catch (err) {
      console.error('Error adding to shelf:', err);
      alert('Failed to add book to shelf');
    }
  };

  const fetchBookIds = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(`${BACKEND_API_URL}/books/ids`);
      if (response.data && response.data.length > 0) {
        setBookIds(response.data);
        setCurrentIndex(0);
        setImageLoaded(false);
      } else {
        setError('No books available');
      }
    } catch (err) {
      console.error('Error fetching book IDs:', err);
      setError('Failed to load book IDs. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleNextBook = () => {
    if (bookIds.length === 0) return;
    
    setImageLoaded(false);
    setImageError(false);
    
    const nextIndex = (currentIndex + 1) % bookIds.length;
    setCurrentIndex(nextIndex);
  };

  if (loading) return <LoadingMessage>Loading your next discovery...</LoadingMessage>;
  if (error) return <ErrorMessage>{error}</ErrorMessage>;
  if (bookIds.length === 0) return <ErrorMessage>No books available</ErrorMessage>;

  const coverUrl = bookIds[currentIndex] ? 
    `${BACKEND_API_URL}/books/cover/${bookIds[currentIndex]}` : 
    '/default-cover.jpg';

  return (
    <Container>
      <CoverContainer>
        <CoverImageWrapper>
          {!imageLoaded && !imageError && (
            <LoadingPlaceholder>
              Loading your next book...
            </LoadingPlaceholder>
          )}
          <StyledCoverImage
            key={bookIds[currentIndex]}
            src={coverUrl}
            alt="Book Cover"
            effect="opacity"
            threshold={100}
            beforeLoad={() => setImageLoaded(false)}
            afterLoad={() => setImageLoaded(true)}
            onError={(e) => {
              setImageError(true);
              e.target.src = '/default-cover.jpg';
            }}
            style={{ opacity: imageLoaded ? 1 : 0 }}
            placeholderSrc="/default-cover.jpg"
          />
        </CoverImageWrapper>
        <ButtonGroup>
          <Button 
            onClick={handleNextBook}
            disabled={loading}
          >
            Discover Next Book
          </Button>
          {userShelves.map(shelf => (
            <ShelfButton
              key={shelf.id}
              onClick={() => addToShelf(shelf.id)}
            >
              Add to {shelf.name}
            </ShelfButton>
          ))}
        </ButtonGroup>
      </CoverContainer>
    </Container>
  );
};

export default DiscoveryCover;