import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

const Container = styled.div`
  padding: 40px;
  background: #FFFFFF;
  border-radius: 16px;
  margin: 40px auto;
  max-width: 800px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  color: #111827;
`;

const Header = styled.div`
  margin-bottom: 32px;
  text-align: left;

  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 12px;

    svg {
      width: 24px;
      height: 24px;
      color: #111827;
    }
  }
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px;
  background: #F9FAFB;
  border-radius: 12px;
  margin-bottom: 24px;

  .stars {
    font-size: 24px;
    color: #FDB022;
    letter-spacing: 2px;
  }

  .count {
    color: #4B5563;
    font-size: 16px;
    font-weight: 500;
  }
`;

const Genres = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin: 24px 0;
`;

const Genre = styled.span`
  background: #F3F4F6;
  color: #111827;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    background: #E5E7EB;
    transform: translateY(-1px);
  }
`;

const GoodReadsLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #111827;
  text-decoration: none;
  font-weight: 500;
  padding: 12px 24px;
  border: 2px solid #E5E7EB;
  border-radius: 8px;
  transition: all 0.2s ease;
  margin-top: 24px;
  
  &:hover {
    background: #F9FAFB;
    border-color: #111827;
    transform: translateY(-2px);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const LoadingState = styled.div`
  padding: 40px;
  text-align: center;
  color: #6B7280;
  font-size: 16px;
`;

const ErrorState = styled.div`
  padding: 40px;
  text-align: center;
  color: #EF4444;
  font-size: 16px;
`;

const GoodReads = ({ title, author }) => {
  const [bookData, setBookData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGoodReadsData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BACKEND_API_URL}/book-reviews/${encodeURIComponent(title)}/${encodeURIComponent(author)}`
        );
        setBookData(response.data);
      } catch (err) {
        console.error('Error fetching GoodReads data:', err);
        setError('Could not load GoodReads information');
      } finally {
        setLoading(false);
      }
    };

    if (title && author) {
      fetchGoodReadsData();
    }
  }, [title, author]);

  if (loading) return <LoadingState>Loading reviews from GoodReads...</LoadingState>;
  if (error) return null;
  if (!bookData) return null;

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const stars = '★'.repeat(fullStars) + (hasHalfStar ? '½' : '');
    return stars;
  };

  return (
    <Container>
      <Header>
        <h3>
          <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" />
          </svg>
          GoodReads Reviews
        </h3>
      </Header>

      <Rating>
        <div className="stars">{renderStars(bookData.rating)}</div>
        <div className="count">
          {bookData.rating}/5 · {bookData.total_ratings.toLocaleString()} ratings
        </div>
      </Rating>

      <Genres>
        {bookData.genres.map((genre, index) => (
          <Genre key={index}>{genre}</Genre>
        ))}
      </Genres>

      <GoodReadsLink 
        href={`https://www.goodreads.com/book/show/${bookData.goodreads_id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        View on GoodReads
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14L21 3" />
        </svg>
      </GoodReadsLink>
    </Container>
  );
};

export default GoodReads;