import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FaLightbulb, FaSpinner } from 'react-icons/fa';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 24px;
  background: #FFFFFF;
  min-height: 100vh;
  color: #111827;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
`;

const Form = styled.form`
  background: #FFFFFF;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  border: 1px solid #E5E7EB;
`;

const Title = styled.h2`
  font-size: 42px;
  font-weight: 700;
  color: #111827;
  margin-bottom: 32px;
  line-height: 1.2;
`;

const Input = styled.input`
  width: 100%;
  padding: 16px;
  margin: 16px 0;
  border: 2px solid #E5E7EB;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.2s ease;
  color: #111827;

  &:focus {
    outline: none;
    border-color: #111827;
    box-shadow: 0 0 0 3px rgba(17, 24, 39, 0.1);
  }

  &::placeholder {
    color: #9CA3AF;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 16px;
  margin: 16px 0;
  border: 2px solid #E5E7EB;
  border-radius: 8px;
  min-height: 120px;
  font-size: 16px;
  font-family: inherit;
  resize: vertical;
  transition: all 0.2s ease;
  line-height: 1.6;
  color: #111827;

  &:focus {
    outline: none;
    border-color: #111827;
    box-shadow: 0 0 0 3px rgba(17, 24, 39, 0.1);
  }

  &::placeholder {
    color: #9CA3AF;
  }
`;

const Button = styled.button`
  background: #111827;
  color: white;
  border: none;
  padding: 16px 32px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background: #000000;
  }

  &:disabled {
    background: #E5E7EB;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const InsightsSection = styled.div`
  background: #FFFFFF;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;
  border: 1px solid #E5E7EB;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const SectionTitle = styled.h3`
  color: #111827;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 2px solid #E5E7EB;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  
  li {
    margin-bottom: 16px;
    padding-left: 24px;
    position: relative;
    color: #4B5563;
    line-height: 1.8;
    font-size: 16px;
    
    &:before {
      content: "•";
      color: #111827;
      position: absolute;
      left: 0;
      font-weight: bold;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const LoadingSpinner = styled(FaSpinner)`
  animation: spin 1s linear infinite;
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const ErrorMessage = styled.div`
  color: #EF4444;
  padding: 16px;
  border-radius: 8px;
  margin: 20px 0;
  background: #FEF2F2;
  border: 1px solid #FCA5A5;
  font-weight: 500;
`;

const BookAction = () => {
  const [bookTitle, setBookTitle] = useState('');
  const [context, setContext] = useState('');
  const [goals, setGoals] = useState('');
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setInsights(null);

    try {
      const response = await axios.post(`${BACKEND_API_URL}/bookaction/insights`, {
        book_title: bookTitle,
        context,
        goals
      });

      setInsights(response.data.insights);
    } catch (err) {
      setError(err.response?.data?.detail || 'Failed to get insights');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Title>How Can I Apply This Book?</Title>
        
        <Input
          type="text"
          value={bookTitle}
          onChange={(e) => setBookTitle(e.target.value)}
          placeholder="Book title (e.g., 'Deep Work by Cal Newport')"
          required
        />

        <TextArea
          value={context}
          onChange={(e) => setContext(e.target.value)}
          placeholder="Your context (e.g., 'I'm building an AI startup and want to improve my focus')"
          required
        />

        <TextArea
          value={goals}
          onChange={(e) => setGoals(e.target.value)}
          placeholder="Your specific goals (e.g., 'Increase productive coding hours, reduce distractions')"
          required
        />

        <Button type="submit" disabled={loading}>
          {loading ? (
            <>
              <LoadingSpinner />
              Generating Insights...
            </>
          ) : (
            <>
              <FaLightbulb />
              Get Action Steps
            </>
          )}
        </Button>
      </Form>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      {insights && (
        <>
          {Object.entries(insights).map(([section, items]) => (
            <InsightsSection key={section}>
              <SectionTitle>{section}</SectionTitle>
              <List>
                {items.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </List>
            </InsightsSection>
          ))}
        </>
      )}
    </Container>
  );
};

export default BookAction;