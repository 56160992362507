import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaBriefcase, FaFileAlt, FaFileWord, FaDownload } from 'react-icons/fa';

const Container = styled.div`
  min-height: 100vh;
  background: #FFFFFF;
  padding: 80px 40px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
`;

const HeroSection = styled.div`
  text-align: center;
  margin-bottom: 80px;
  padding: 40px 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: #111827;
    border-radius: 2px;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  font-weight: 800;
  color: #111827;
  margin-bottom: 16px;
  letter-spacing: -0.02em;
  line-height: 1.2;
`;

const Subtitle = styled.p`
  font-size: 20px;
  color: #4B5563;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 32px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureCard = styled.div`
  background: #FFFFFF;
  border-radius: 16px;
  padding: 32px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #E5E7EB;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  height: 100%;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: #111827;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    border-color: #111827;

    &::before {
      transform: scaleX(1);
    }
  }
`;

const CardContent = styled.div`
  flex-grow: 1;
`;

const IconContainer = styled.div`
  font-size: 24px;
  color: #111827;
  margin-bottom: 24px;
  background: #F9FAFB;
  border-radius: 12px;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  ${FeatureCard}:hover & {
    background: #111827;
    color: #FFFFFF;
    transform: scale(1.1);
  }
`;

const FeatureTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 20px;
  letter-spacing: -0.01em;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FeatureItem = styled.li`
  color: #4B5563;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 12px;
  padding-left: 24px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 6px;
    height: 6px;
    background: #111827;
    border-radius: 50%;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const UseButton = styled.button`
  background: #111827;
  color: #FFFFFF;
  border: none;
  padding: 14px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-top: 24px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(255, 255, 255, 0.2), transparent);
    transform: translateY(-100%);
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    &::after {
      transform: translateY(0);
    }
  }

  &:active {
    transform: translateY(0);
  }
`;

const Features = () => {
  const navigate = useNavigate();

  const features = [
    {
      title: 'Dynamic Book Discovery',
      description: [
        'Instantly reveal book covers filtered by subjects or genres',
        'Use multiple filters to refine results and explore books',
        'Switch between Cover Mode, Title Mode, and Preview Mode'
      ],
      path: '/discovery',
      icon: <FaBriefcase />
    },
    {
      title: 'BookSense AI',
      description: [
        'Get instant AI-powered summaries of any book',
        'Convert text summaries to audio for on-the-go learning',
        'Access key insights and takeaways instantly',
        'Integrate with GoodReads for community reviews'
      ],
      path: '/book-sense',
      icon: <FaFileAlt />
    },
    {
      title: 'BookAction',
      description: [
        'Transform book knowledge into actionable steps',
        'Get personalized action plans based on content',
        'Track your progress and implementation',
        'Create custom reading paths for your goals'
      ],
      path: '/book-action',
      icon: <FaFileWord />
    },
    {
      title: 'Smart Download Center',
      description: [
        'Access secure download links instantly',
        'Multiple format support (PDF & EPUB)',
        'Preview content before downloading',
        'Seamless e-reader integration'
      ],
      path: '/download-url',
      icon: <FaDownload />
    }
  ];

  return (
    <Container>
      <HeroSection>
        <Title>Bookhinge Features</Title>
        <Subtitle>Your Gateway to Endless Book Discovery</Subtitle>
      </HeroSection>
      
      <FeaturesGrid>
        {features.map((feature, index) => (
          <FeatureCard key={index} onClick={() => navigate(feature.path)}>
            <CardContent>
              <IconContainer>
                {feature.icon}
              </IconContainer>
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureList>
                {feature.description.map((item, i) => (
                  <FeatureItem key={i}>{item}</FeatureItem>
                ))}
              </FeatureList>
            </CardContent>
            <UseButton>Explore {feature.title}</UseButton>
          </FeatureCard>
        ))}
      </FeaturesGrid>
    </Container>
  );
};

export default Features;