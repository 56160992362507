import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

const Container = styled.div`
  padding: 40px;
  background: #FFFFFF;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
`;

const TitleCard = styled.div`
  background: #FFFFFF;
  padding: 40px;
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  transition: transform 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #4F46E5, #818CF8);
  }
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: 700;
  color: #111827;
  margin-bottom: 32px;
  line-height: 1.4;
  position: relative;
  padding: 0 20px;

  &::before, &::after {
    content: '"';
    position: absolute;
    font-size: 64px;
    color: #E5E7EB;
    font-family: Georgia, serif;
  }

  &::before {
    left: -10px;
    top: -20px;
  }

  &::after {
    right: -10px;
    bottom: -40px;
  }
`;

const Button = styled.button`
  padding: 16px 32px;
  background: #111827;
  color: #FFFFFF;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  margin-top: 24px;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(255, 255, 255, 0.2), transparent);
    transform: translateY(-100%);
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

    &::after {
      transform: translateY(0);
    }
  }

  &:active {
    transform: translateY(0);
  }
`;

const LoadingState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  font-size: 18px;
  color: #6B7280;
  font-weight: 500;
  
  &::after {
    content: '...';
    animation: loading 1.5s infinite;
  }

  @keyframes loading {
    0% { content: '.'; }
    33% { content: '..'; }
    66% { content: '...'; }
  }
`;

const ErrorState = styled.div`
  padding: 24px;
  background: #FEF2F2;
  color: #991B1B;
  border-radius: 12px;
  font-weight: 500;
  text-align: center;
  max-width: 500px;
  margin: 40px auto;
`;

const Counter = styled.div`
  font-size: 14px;
  color: #6B7280;
  margin-top: 16px;
  font-weight: 500;
`;

const DiscoveryTitle = () => {
  const [titles, setTitles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchTitles();
  }, []);

  const fetchTitles = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(`${BACKEND_API_URL}/books/titles`);
      setTitles(response.data);
      setCurrentIndex(0);
    } catch (err) {
      console.error('Error fetching book titles:', err);
      setError('Failed to load book titles. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleNextTitle = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % titles.length);
  };

  if (loading) return <Container><LoadingState>Discovering titles</LoadingState></Container>;
  if (error) return <Container><ErrorState>{error}</ErrorState></Container>;
  if (!titles.length) return <Container><ErrorState>No titles available</ErrorState></Container>;

  return (
    <Container>
      <TitleCard>
        <Title>{titles[currentIndex]}</Title>
        <Button onClick={handleNextTitle}>
          Discover Next Title
        </Button>
        <Counter>
          {currentIndex + 1} of {titles.length}
        </Counter>
      </TitleCard>
    </Container>
  );
};

export default DiscoveryTitle;