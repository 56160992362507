import React, { useEffect, useState } from 'react';
import { useUser } from './UserContext';
import { useParams, useNavigate } from 'react-router-dom';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

function VerifyLogin() {
    const { setUser } = useUser();
    const { token } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('Verifying login...');

    useEffect(() => {
        const verifyLogin = async () => {
            try {
                // Add timeout to the fetch request
                const controller = new AbortController();
                const timeoutId = setTimeout(() => controller.abort(), 30000); // 30 second timeout

                const response = await fetch(
                    `${BACKEND_API_URL}/verify-login/${token}`,
                    { signal: controller.signal }
                );
                
                clearTimeout(timeoutId);
                const result = await response.json();

                if (response.ok) {
                    setStatus('Login successful! Redirecting...');
                    setUser({ token: result.access_token });
                    localStorage.setItem('authToken', result.access_token);
                    localStorage.setItem('sessionToken', result.session_token);
                    navigate('/features');
                } else {
                    setError(result.detail || 'Verification failed');
                    setTimeout(() => navigate('/signin'), 3000);
                }
            } catch (error) {
                const errorMessage = error.name === 'AbortError' 
                    ? 'Verification timed out. Please try again.' 
                    : 'An unexpected error occurred';
                    
                setError(errorMessage);
                setTimeout(() => navigate('/signin'), 3000);
            }
        };

        verifyLogin();
    }, [token, setUser, navigate]);

    return (
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
            {error ? (
                <div style={{ color: 'red' }}>
                    Error: {error}. Redirecting to login...
                </div>
            ) : (
                <div>
                    {status}
                    <div style={{ marginTop: '1rem' }}>
                        <div className="loading-spinner"></div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default VerifyLogin;