import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

const Container = styled.div`
  padding: 40px 24px;
  background: #FFFFFF;
  min-height: 100vh;
  color: #111827;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
`;

const Header = styled.div`
  max-width: 1200px;
  margin: 0 auto 40px;
  
  h1 {
    font-size: 42px;
    font-weight: 700;
    color: #111827;
  }
`;

const ShelvesContainer = styled.div`
  display: flex;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Sidebar = styled.div`
  width: 280px;
  background: #FFFFFF;
  padding: 32px;
  border-radius: 16px;
  height: fit-content;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  border: 1px solid #E5E7EB;

  h2 {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 24px;
  }
`;

const BooksGrid = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 32px;
`;

const ShelfButton = styled.button`
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 8px;
  background: ${props => props.active ? '#111827' : '#FFFFFF'};
  color: ${props => props.active ? '#FFFFFF' : '#111827'};
  border: 2px solid ${props => props.active ? '#111827' : '#E5E7EB'};
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-color: #111827;
  }
`;

const CreateShelfButton = styled.button`
  width: 100%;
  padding: 12px;
  background: #111827;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 16px;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background: #000000;
  }

  &:disabled {
    background: #E5E7EB;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const BookCard = styled.div`
  background: #FFFFFF;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  border: 1px solid #E5E7EB;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.12);
  }
`;

const BookInfo = styled.div`
  padding: 16px;
`;

const BookTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: #111827;
  margin: 0 0 8px 0;
  line-height: 1.4;
`;

const BookAuthor = styled.p`
  font-size: 14px;
  color: #6B7280;
  margin: 0;
`;

const RemoveButton = styled.button`
  width: 100%;
  padding: 12px;
  background: #EF4444;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: 500;
  opacity: 0;
  transition: all 0.2s ease;

  ${BookCard}:hover & {
    opacity: 1;
  }

  &:hover {
    background: #DC2626;
  }
`;

const EmptyShelf = styled.div`
  grid-column: 1 / -1;
  text-align: center;
  padding: 40px;
  background: #F9FAFB;
  border-radius: 16px;
  color: #6B7280;
  font-size: 18px;
  border: 2px dashed #E5E7EB;
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  padding: 32px;
  border-radius: 16px;
  z-index: 1000;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);

  h2 {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 24px;
  }

  input {
    width: 100%;
    padding: 12px;
    margin-bottom: 24px;
    border: 2px solid #E5E7EB;
    border-radius: 8px;
    font-size: 16px;
    transition: all 0.2s ease;

    &:focus {
      outline: none;
      border-color: #111827;
      box-shadow: 0 0 0 3px rgba(17, 24, 39, 0.1);
    }
  }

  button {
    margin: 0 8px;
    padding: 12px 24px;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;

    &:first-of-type {
      background: #111827;
      color: white;
      border: none;

      &:hover {
        background: #000000;
        transform: translateY(-2px);
      }
    }

    &:last-of-type {
      background: #FFFFFF;
      color: #111827;
      border: 2px solid #E5E7EB;

      &:hover {
        border-color: #111827;
        transform: translateY(-2px);
      }
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(17, 24, 39, 0.75);
  backdrop-filter: blur(4px);
  z-index: 999;
`;

const CoverImage = styled(LazyLoadImage)`
  width: 100%;
  aspect-ratio: 2/3;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
`;

const PremiumNote = styled.p`
  font-size: 14px;
  color: #6B7280;
  margin-top: 12px;
  padding: 12px;
  background: #F9FAFB;
  border-radius: 8px;
  border: 1px solid #E5E7EB;
`;

const BookShelf = () => {
  const navigate = useNavigate();
  const [shelves, setShelves] = useState([]);
  const [activeShelf, setActiveShelf] = useState(null);
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newShelfName, setNewShelfName] = useState('');
  const [isPremium, setIsPremium] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        setUserId(payload.user_id);
      } catch (error) {
        console.error('Error decoding token:', error);
        navigate('/signin');
      }
    } else {
      navigate('/signin');
    }
  }, [navigate]);

  useEffect(() => {
    if (userId) {
      fetchShelves();
      checkPremiumStatus();
    }
  }, [userId]);

  const checkPremiumStatus = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        navigate('/signin');
        return;
      }
  
      const payload = JSON.parse(atob(token.split('.')[1]));
      const userId = payload.user_id;
  
      const response = await axios.get(`${BACKEND_API_URL}/user/${userId}/status`);
      setIsPremium(response.data.is_premium);
    } catch (err) {
      console.error('Error checking premium status:', err);
      if (err.response?.status === 401) {
        navigate('/signin');
      }
    }
  };

  const fetchShelves = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const payload = JSON.parse(atob(token.split('.')[1]));
      const userId = payload.user_id;

      const response = await axios.get(`${BACKEND_API_URL}/bookshelf/shelves`, {
        params: { user_id: userId }
      });
      setShelves(response.data);
      if (response.data.length > 0) {
        setActiveShelf(response.data[0].id);
        fetchShelfBooks(response.data[0].id);
      }
    } catch (err) {
      setError('Failed to load shelves');
      console.error('Error fetching shelves:', err);
    }
  };

  const fetchShelfBooks = async (shelfId) => {
    try {
      setLoading(true);
      const response = await axios.get(`${BACKEND_API_URL}/bookshelf/${shelfId}/books`, {
        params: { user_id: userId }
      });
      
      const booksWithCovers = response.data.map(book => ({
        ...book,
        coverUrl: `${BACKEND_API_URL}/books/cover/${book.id}`
      }));
      
      setBooks(booksWithCovers);
    } catch (err) {
      setError('Failed to load books');
      console.error('Error fetching shelf books:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateShelf = async () => {
    if (!isPremium) {
      alert('Upgrade to Premium to create custom shelves!');
      return;
    }

    try {
      await axios.post(`${BACKEND_API_URL}/bookshelf/create`, {
        user_id: userId,
        shelf_name: newShelfName
      });
      setShowCreateModal(false);
      setNewShelfName('');
      fetchShelves();
    } catch (err) {
      console.error('Error creating shelf:', err);
      alert(err.response?.data?.detail || 'Failed to create shelf');
    }
  };

  const handleRemoveBook = async (bookId) => {
    try {
      await axios.delete(`${BACKEND_API_URL}/bookshelf/${activeShelf}/remove/${bookId}`, {
        params: { user_id: userId }
      });
      fetchShelfBooks(activeShelf);
    } catch (err) {
      console.error('Error removing book:', err);
      alert('Failed to remove book');
    }
  };

  const handleBookClick = (bookId) => {
    navigate(`/preview/${bookId}`);
  };

  return (
    <Container>
      <Header>
        <h1>My Library</h1>
      </Header>

      <ShelvesContainer>
        <Sidebar>
          <h2>Shelves</h2>
          {shelves.map(shelf => (
            <ShelfButton
              key={shelf.id}
              active={activeShelf === shelf.id}
              onClick={() => {
                setActiveShelf(shelf.id);
                fetchShelfBooks(shelf.id);
              }}
            >
              {shelf.name} ({shelf.book_count})
            </ShelfButton>
          ))}
          <CreateShelfButton 
            onClick={() => setShowCreateModal(true)}
            disabled={!isPremium}
          >
            Create New Shelf
          </CreateShelfButton>
          {!isPremium && (
            <PremiumNote>
              Upgrade to Premium to create custom shelves!
            </PremiumNote>
          )}
        </Sidebar>

        <BooksGrid>
          {books.map(book => (
            <BookCard 
              key={book.id} 
              onClick={() => handleBookClick(book.id)}
              style={{ cursor: 'pointer' }}
            >
              <CoverImage 
                src={book.coverUrl}
                alt={book.title}
                effect="opacity"
                threshold={100}
                onError={(e) => {
                  e.target.src = '/default-cover.jpg';
                }}
              />
              <BookInfo>
                <BookTitle>{book.title}</BookTitle>
                <BookAuthor>{book.author}</BookAuthor>
              </BookInfo>
              <RemoveButton 
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveBook(book.id);
                }}
              >
                Remove
              </RemoveButton>
            </BookCard>
          ))}
          {books.length === 0 && (
            <EmptyShelf>
              No books in this shelf yet
            </EmptyShelf>
          )}
        </BooksGrid>
      </ShelvesContainer>

      {showCreateModal && (
        <>
          <Overlay onClick={() => setShowCreateModal(false)} />
          <Modal>
            <h2>Create New Shelf</h2>
            <input
              type="text"
              value={newShelfName}
              onChange={(e) => setNewShelfName(e.target.value)}
              placeholder="Enter shelf name"
            />
            <button onClick={handleCreateShelf}>Create</button>
            <button onClick={() => setShowCreateModal(false)}>Cancel</button>
          </Modal>
        </>
      )}
    </Container>
  );
};

export default BookShelf;