import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AudioSummary from './AudioSummary';
import styled from 'styled-components';
import GoodReads from './GoodReads';
import Filters from './Filters';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useNavigate } from 'react-router-dom';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || 'https://bookhingebackend.onrender.com';

const PageContainer = styled.div`
  min-height: 100vh;
  background: #FFFFFF;
  color: #111827;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.95);
  padding: 24px;
  backdrop-filter: blur(10px);
  z-index: 100;
  border-bottom: 1px solid #E5E7EB;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
`;

const ContentContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 24px;
`;

const PreviewContent = styled.div`
  background: #F9FAFB;
  padding: 20px;
  border-radius: 16px;
  margin: 32px 0;
  color: #1F2937;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #4F46E5, #818CF8);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin: 32px 0;
  flex-wrap: wrap;
`;

const Button = styled.button`
  background: ${props => props.primary ? '#4F46E5' : props.secondary ? '#6B7280' : '#10B981'};
  color: white;
  border: none;
  padding: 14px 28px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(255, 255, 255, 0.2), transparent);
    transform: translateY(-100%);
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

    &::after {
      transform: translateY(0);
    }
  }

  &:disabled {
    background: #E5E7EB;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const BookInfo = styled.div`
  text-align: center;
  margin: 40px 0;
  padding: 32px;
  background: #F9FAFB;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
`;

const BookTitle = styled.h2`
  font-size: 32px;
  font-weight: 700;
  color: #111827;
  margin: 16px 0;
  line-height: 1.4;
`;

const BookAuthor = styled.h3`
  font-size: 20px;
  color: #6B7280;
  margin: 8px 0;
  font-weight: 500;
`;

const CoverImage = styled(LazyLoadImage)`
  max-width: 240px;
  border-radius: 12px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  margin: 32px 0;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15);
  }
`;

const LoadingPlaceholder = styled.div`
  width: 240px;
  height: 360px;
  margin: 32px auto;
  background: linear-gradient(45deg, #F3F4F6, #E5E7EB);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6B7280;
  font-weight: 500;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
    animation: loading 1.5s infinite;
  }

  @keyframes loading {
    100% {
      left: 150%;
    }
  }
`;

const PreviewCounter = styled.div`
  text-align: center;
  margin: 16px 0;
  color: ${props => props.low ? '#DC2626' : '#6B7280'};
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  background: ${props => props.low ? '#FEF2F2' : '#F3F4F6'};
  border-radius: 8px;
  display: inline-block;
`;

const PremiumFeatureNote = styled.div`
  color: #6B7280;
  font-size: 14px;
  margin: 24px 0;
  padding: 16px;
  border: 1px solid #E5E7EB;
  border-radius: 12px;
  background: #F9FAFB;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  svg {
    color: #FCD34D;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #FFFFFF;
  padding: 32px;
  border-radius: 16px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);

  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 24px;
  }
`;

const ShelfList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const LoadingState = styled.div`
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #6B7280;
  font-weight: 500;
`;

const ErrorState = styled.div`
  padding: 24px;
  background: #FEF2F2;
  color: #991B1B;
  border-radius: 12px;
  font-weight: 500;
  text-align: center;
  max-width: 500px;
  margin: 40px auto;
`;

const FormattedPreviewContent = styled.div`
  white-space: pre-wrap;
  text-align: justify;
  padding: 20px;
  font-family: 'Georgia', serif;
  font-size: 18px;
  line-height: 1.8;
  letter-spacing: 0.3px;

  p {
    margin-bottom: 1.5em;
  }

  // Style quotes
  blockquote {
    border-left: 3px solid #4F46E5;
    margin: 1.5em 0;
    padding-left: 1em;
    font-style: italic;
  }

  // Add spacing between paragraphs
  .paragraph {
    margin-bottom: 1.5em;
  }

  // Style chapter headings
  .chapter-heading {
    font-size: 1.5em;
    font-weight: bold;
    margin: 1.5em 0 1em;
    color: #111827;
  }
`;

const DiscoveryPreview = () => {
  const navigate = useNavigate();
  const [previews, setPreviews] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isRevealed, setIsRevealed] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [showGoodReads, setShowGoodReads] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [userId, setUserId] = useState(null);
  const [remainingPreviews, setRemainingPreviews] = useState(null);
  const [bookDetails, setBookDetails] = useState(null);
  const [premiumFeatures, setPremiumFeatures] = useState(null);
  const [shelves, setShelves] = useState([]);
  const [showShelfModal, setShowShelfModal] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        setUserId(payload.user_id);
      } catch (error) {
        console.error('Error decoding token:', error);
        navigate('/signin');
      }
    } else {
      navigate('/signin');
    }
  }, [navigate]);

  useEffect(() => {
    if (userId) {
      fetchPreviews();
      fetchShelves();
    }
  }, [userId, selectedCategories]);

  const fetchPreviews = async () => {
    try {
      setLoading(true);
      setError(null);
      const [categories, subcategories] = selectedCategories.reduce(
        ([cats, subs], key) => {
          const [cat, sub] = key.split('-');
          return [cats.add(cat), subs.add(sub)];
        },
        [new Set(), new Set()]
      );
      const categoriesParam = Array.from(categories).join(',');
      const subcategoriesParam = Array.from(subcategories).join(',');
      const url = `${BACKEND_API_URL}/books/previews?user_id=${userId}${categoriesParam ? `&categories=${categoriesParam}` : ''}${subcategoriesParam ? `&subcategories=${subcategoriesParam}` : ''}`;
      const response = await axios.get(url);
      setPreviews(response.data.filter(preview => preview.preview_content));
      setRemainingPreviews(response.data[0]?.remaining_previews);
      setCurrentIndex(0);
      setIsRevealed(false);
      setBookDetails(null);
    } catch (err) {
      console.error('Error fetching previews:', err);
      if (err.response?.status === 401) navigate('/signin');
      else setError('Failed to load previews. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const fetchShelves = async () => {
    try {
      const response = await axios.get(`${BACKEND_API_URL}/bookshelf/shelves`, {
        params: { user_id: userId }
      });
      setShelves(response.data);
    } catch (err) {
      console.error('Error fetching shelves:', err);
    }
  };

  const handleReveal = async () => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/books/reveal/${previews[currentIndex].id}?user_id=${userId}`
      );
      setBookDetails(response.data);
      setPremiumFeatures(response.data.premium_features);
      setRemainingPreviews(response.data.remaining_previews);
      setIsRevealed(true);
    } catch (err) {
      console.error('Error revealing book:', err);
      setError(err.response?.status === 403 ? err.response.data.detail : 'Failed to reveal book details.');
    }
  };

  const handleNextPreview = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % previews.length);
    setIsRevealed(false);
    setImageLoaded(false);
    setImageError(false);
    setShowGoodReads(false);
    setBookDetails(null);
  };

  const handleAddToShelf = async (shelfId) => {
    try {
      await axios.post(`${BACKEND_API_URL}/bookshelf/${shelfId}/add`, null, {
        params: {
          book_id: previews[currentIndex].id,
          user_id: userId
        }
      });
      setShowShelfModal(false);
    } catch (err) {
      console.error('Error adding to shelf:', err);
      alert(err.response?.data?.detail || 'Failed to add book to shelf');
    }
  };

  if (loading) return <LoadingState>Loading your next discovery...</LoadingState>;
  if (error) return <ErrorState>{error}</ErrorState>;
  if (!previews.length) return <ErrorState>No previews available</ErrorState>;

  const currentPreview = previews[currentIndex];

  const formatPreviewContent = (content) => {
    if (!content) return '';

    // Remove extra whitespace and normalize line breaks
    let formatted = content.replace(/\s+/g, ' ').trim();

    // Split into paragraphs
    const paragraphs = formatted.split(/(?<=\.|\?|\!) (?=[A-Z])/g);

    // Format each paragraph
    return paragraphs.map((paragraph, index) => {
      // Detect if it's likely a chapter heading
      const isHeading = /^(Chapter|Introduction|Preface|Part)/i.test(paragraph);
      
      // Clean up any remaining formatting issues
      const cleanParagraph = paragraph
        .replace(/\s+/g, ' ')
        .trim()
        .replace(/\.{3,}/g, '...')
        .replace(/\"{2,}/g, '"');

      return (
        <div 
          key={index} 
          className={isHeading ? 'chapter-heading' : 'paragraph'}
        >
          {cleanParagraph}
        </div>
      );
    });
  };

  return (
    <PageContainer>
      <Header>
        <Filters 
          selectedCategories={selectedCategories}
          onCategoryChange={setSelectedCategories}
        />
        {remainingPreviews && remainingPreviews !== "unlimited" && (
          <PreviewCounter low={remainingPreviews <= 3}>
            {remainingPreviews} previews remaining today
          </PreviewCounter>
        )}
      </Header>
      
      <ContentContainer>
        <PreviewContent>
          <FormattedPreviewContent>
            {formatPreviewContent(currentPreview.preview_content)}
          </FormattedPreviewContent>
        </PreviewContent>

        {isRevealed && bookDetails ? (
          <>
            <BookInfo>
              <BookTitle>{bookDetails.title}</BookTitle>
              <BookAuthor>by {bookDetails.author}</BookAuthor>
              {!imageLoaded && !imageError && (
                <LoadingPlaceholder>Loading Cover...</LoadingPlaceholder>
              )}
              <CoverImage 
                src={`${BACKEND_API_URL}/books/cover/${currentPreview.id}`}
                alt={`Cover of ${bookDetails.title}`}
                effect="opacity"
                threshold={100}
                beforeLoad={() => setImageLoaded(false)}
                afterLoad={() => setImageLoaded(true)}
                onError={(e) => {
                  setImageError(true);
                  e.target.src = '/default-cover.jpg';
                }}
                style={{ opacity: imageLoaded ? 1 : 0 }}
                placeholderSrc="/default-cover.jpg"
              />
            </BookInfo>

            {!premiumFeatures?.ai_summary && (
              <PremiumFeatureNote>
                <svg viewBox="0 0 24 24" width="20" height="20" fill="currentColor">
                  <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                </svg>
                Upgrade to Pro to access AI-powered book summaries
              </PremiumFeatureNote>
            )}
            
            {bookDetails.ai_summary && premiumFeatures?.ai_summary && (
              <AudioSummary 
                bookId={currentPreview.id} 
                summary={bookDetails.ai_summary}
              />
            )}

            <ButtonGroup>
              <Button 
                onClick={() => setShowGoodReads(!showGoodReads)}
                disabled={!premiumFeatures?.goodreads}
              >
                {showGoodReads ? 'Hide GoodReads Reviews' : 'Show GoodReads Reviews'}
                {!premiumFeatures?.goodreads && ' (Pro)'}
              </Button>
              <Button secondary onClick={handleNextPreview}>
                Next Book
              </Button>
              <Button onClick={() => setShowShelfModal(true)}>
                Add to Shelf
              </Button>
            </ButtonGroup>

            {showGoodReads && premiumFeatures?.goodreads && (
              <GoodReads 
                title={bookDetails.title}
                author={bookDetails.author}
              />
            )}
          </>
        ) : (
          <ButtonGroup>
            <Button primary onClick={handleReveal}>
              Reveal Title & Author
            </Button>
            <Button secondary onClick={handleNextPreview}>
              Next Book
            </Button>
          </ButtonGroup>
        )}
      </ContentContainer>

      {showShelfModal && (
        <Modal onClick={() => setShowShelfModal(false)}>
          <ModalContent onClick={e => e.stopPropagation()}>
            <h3>Add to Shelf</h3>
            <ShelfList>
              {shelves.map(shelf => (
                <Button key={shelf.id} onClick={() => handleAddToShelf(shelf.id)}>
                  {shelf.name}
                </Button>
              ))}
              <Button secondary onClick={() => setShowShelfModal(false)}>
                Cancel
              </Button>
            </ShelfList>
          </ModalContent>
        </Modal>
      )}
    </PageContainer>
  );
};

export default DiscoveryPreview;